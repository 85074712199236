<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
    v-if="serverDataLoaded"
  >
    <div class="bg-white poppins">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Edit SKU</h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 py-7">
        <!--begin::Body-->
        <div>
          <div class="row ma-0">
            <!-- client -->
            <div class="h-75px py-0 col-12 col-sm-4">
              <v-select
                v-model="formData.customer_id"
                label="Client"
                item-text="text"
                item-value="index"
                :items="serverData.clients"
                clearable
                outlined
                dense
                :error-messages="customer_idErrors"
                @input="$v.formData.customer_id.$touch()"
                @blur="$v.formData.customer_id.$touch()"
              ></v-select>
            </div>
            <!-- sku type -->
            <div class="h-75px py-0 col-12 col-sm-4">
              <v-select
                v-model="formData.sku_type_id"
                label="SKU Type"
                item-text="text"
                item-value="index"
                :items="serverData.sku_types"
                clearable
                outlined
                dense
                :error-messages="sku_type_idErrors"
                @input="$v.formData.sku_type_id.$touch()"
                @blur="$v.formData.sku_type_id.$touch()"
              ></v-select>
            </div>
            <!-- system code -->
            <div class="h-75px py-0 col-12 col-sm-6 col-md-4">
              <v-text-field
                v-model="formData.system_code"
                label="System Code"
                clearable
                outlined
                dense
                :error-messages="system_codeErrors"
                @input="$v.formData.system_code.$touch()"
                @blur="$v.formData.system_code.$touch()"
              ></v-text-field>
            </div>
            <!-- rotation rules -->
            <div class="h-75px py-0 col-12 col-md-6">
              <v-select
                v-model="formData.rotation_rules"
                label="Rotation rules"
                :items="serverData.rotation_rules"
                item-text="text"
                item-value="index"
                clearable
                outlined
                dense
              ></v-select>
            </div>
            <!-- photo -->
            <div class="py-0 col-12 col-md-6">
              <v-file-input
                v-model="formData.photo"
                show-size
                counter
                multiple
                dense
                outlined
                accept="image/png, image/jpeg, image/bmp"
                append-icon="mdi-camera"
                label="Photo"
                clearable
              ></v-file-input>
            </div>
            <!-- description -->
            <div class="h-75px py-0 col-12">
              <v-text-field
                v-model="formData.description"
                label="Description"
                clearable
                outlined
                dense
              ></v-text-field>
            </div>
          </div>
          <!-- category group start -->
          <input-group label="Category">
            <div class="py-0 col-12 col-sm-4">
              <v-select
                v-model="formData.brand_id"
                label="Brand"
                item-text="text"
                item-value="index"
                :items="serverData.brands"
                clearable
                hide-details="auto"
                outlined
                dense
              ></v-select>
            </div>
            <div class="py-0 col-12 col-sm-4">
              <v-select
                v-model="formData.category_id"
                label="Category"
                item-text="text"
                item-value="index"
                :items="serverData.categories"
                clearable
                hide-details="auto"
                outlined
                dense
                @change="loadSubCategories"
              ></v-select>
            </div>
            <div class="py-0 col-12 col-sm-4">
              <v-select
                v-model="formData.sub_category_id"
                label="Sub category"
                item-text="text"
                item-value="index"
                :items="serverData.sub_categories"
                clearable
                hide-details="auto"
                outlined
                dense
              ></v-select>
            </div>
          </input-group>
          <!-- categroy group end -->
          <!-- dimensions & volumetric -->
          <input-group label="Dimensions & volumetric">
            <!-- width -->
            <div class="py-0 col-12 col-sm-6 col-md-2">
              <v-text-field
                v-model="formData.width"
                label="Width"
                @change="() => validateMinValue('width', 0, 'formData')"
                type="Number"
                clearable
                hide-details="auto"
                outlined
                :min="0"
                dense
              ></v-text-field>
            </div>
            <!-- height -->
            <div class="py-0 col-12 col-sm-6 col-md-2">
              <v-text-field
                v-model="formData.height"
                label="Height"
                type="Number"
                @change="() => validateMinValue('height', 0, 'formData')"
                clearable
                hide-details="auto"
                :min="0"
                outlined
                dense
              ></v-text-field>
            </div>
            <!-- length -->
            <div class="py-0 col-12 col-sm-6 col-md-2">
              <v-text-field
                v-model="formData.length"
                label="Length"
                type="Number"
                @change="() => validateMinValue('length', 0, 'formData')"
                clearable
                :min="0"
                hide-details="auto"
                outlined
                dense
              ></v-text-field>
            </div>
            <!-- weight -->
            <div class="py-0 col-12 col-sm-6 col-md-2">
              <v-text-field
                v-model="formData.weight"
                label="Weight"
                type="Number"
                @change="() => validateMinValue('weight', 0, 'formData')"
                clearable
                :min="0"
                hide-details="auto"
                outlined
                dense
              ></v-text-field>
            </div>
            <!-- volume -->
            <div class="py-0 col-12 col-sm-6 col-md-2">
              <v-text-field
                :value="volume"
                label="Volume"
                type="Number"
                hide-details="auto"
                disabled
                outlined
                dense
              ></v-text-field>
            </div>
            <div class="py-0 col-12 col-sm-6 col-md-2">
              <v-text-field
                :value="cbm"
                label="CBM"
                type="text"
                hide-details
                disabled
                outlined
                dense
              ></v-text-field>
            </div>
          </input-group>
          <!-- end of dimensions & volumetric -->
          <!-- dimension group start -->
          <input-group label="Other Details">
            <!-- country of origin -->
            <div class="py-0 col-12 col-sm-6 col-md-3">
              <v-select
                v-model="formData.country_id"
                label="Country of origin"
                item-text="text"
                item-value="index"
                :items="serverData.countries"
                clearable
                hide-details="auto"
                outlined
                dense
              ></v-select>
            </div>
            <!-- currrency -->
            <div class="py-0 col-12 col-sm-6 col-md-3">
              <v-select
                v-model="formData.currency_id"
                label="Currency"
                item-text="text"
                item-value="index"
                :items="serverData.currencies"
                clearable
                hide-details="auto"
                outlined
                dense
              ></v-select>
            </div>
            <!-- HS Code -->
            <div class="py-0 col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.hs_code"
                label="HS Code"
                clearable
                hide-details="auto"
                outlined
                dense
              ></v-text-field>
            </div>
            <!-- size -->
            <div class="py-0 col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.size"
                label="Size"
                clearable
                hide-details="auto"
                outlined
                dense
              ></v-text-field>
            </div>
            <!-- color -->
            <div class="mt-6 py-0 col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.color"
                label="Color"
                clearable
                hide-details="auto"
                outlined
                dense
              ></v-text-field>
            </div>
            <!-- design number -->
            <div class="mt-6 py-0 col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.design_number"
                label="Design number"
                clearable
                hide-details="auto"
                outlined
                dense
              ></v-text-field>
            </div>
            <!-- selling price -->
            <div class="mt-6 py-0 col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.price"
                label="Selling price"
                clearable
                hide-details="auto"
                outlined
                dense
              ></v-text-field>
            </div>
            <!-- cost price -->
            <div class="mt-6 py-0 col-12 col-sm-6 col-md-3">
              <v-text-field
                v-model="formData.cost_price"
                label="Cost price"
                clearable
                hide-details="auto"
                outlined
                dense
              ></v-text-field>
            </div>
          </input-group>
          <!-- dimension group end -->
          <!-- services -->
          <input-group label="Services">
            <!-- expiry control -->
            <div
              class="col-12 col-sm-6 col-md-4 d-flex align-start ma-0 py-0 pl-6"
            >
              <h5 class="pr-4 pt-5 font-weight-regular">Expiry Control</h5>
              <v-switch
                v-model="formData.expiry_control"
                hide-details="auto"
                inset
                dense
              ></v-switch>
            </div>
            <!-- serial control -->
            <div class="col-12 col-sm-6 col-md-4 d-flex align-start ma-0 py-0">
              <h5 class="pr-4 pt-5 font-weight-regular">
                Serial number control
              </h5>
              <v-switch
                v-model="formData.serial_number_control"
                hide-details="auto"
                inset
                dense
              ></v-switch>
            </div>
          </input-group>
          <!-- end of services -->

          <div class="pa-4 w-100">
            <SkuDatatable
              :setSku="setSkus"
              :codes="formData.codes"
              :skuId="formData.id"
              :customer="formData.customer_id"
            ></SkuDatatable>
          </div>
        </div>

        <!--end::Body-->
        <div class="my-4 h-75px d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetEditForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitEditForm"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import SkuDatatable from "@/own/components/fulfillment/skus/skuAddContainer/SkuDatatable";
import SwalEdited from "@/core/services/AlertServices/SwalEdited";
import InputGroup from "@/own/components/fulfillment/skus/InputGroup.vue";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

export default {
  name: "EditItem",
  mixins: [validationMixin, fieldValueValidation],
  components: {
    SkuDatatable,
    InputGroup,
  },
  validations: {
    formData: {
      customer_id: { required },
      system_code: { required },
      sku_type_id: { required },
      // rotation_rules: { required },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      customer_id: null,
      system_code: null,
      sku_type_id: null,
      rotation_rules: null,
      description: null,
      weight: null,
      length: null,
      width: null,
      height: null,
      brand_id: null,
      photo: null,
      expiry_control: 0,
      serial_number_control: 0,
      hs_code: null,
      country_id: null,
      currency_id: null,
      price: null,
      cost_price: null,
      category_id: null,
      size: null,
      color: null,
      design_number: null,
      cbm: null,
    },
  }),
  methods: {
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/fulfillment/skus/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.formData = response.data.sku;
          this.formData.codes.forEach((altsku) => {
            altsku.is_from_server = 1;
          });
          this.formData.photo = null;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },
    loadSubCategories() {
      this.pageLoader(true);
      ApiService.post(`/skus/sub-categories`, {
        parent_id: this.formData.category_id,
      })
        .then(({ data }) => {
          this.serverData = {
            ...this.serverData,
            sub_categories: data.sub_categories,
          };
        })
        .finally(() => {
          this.pageLoader(false);
        });
    },

    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;

      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      return errors;
    },
    setSkus(item) {
      this.formData.codes = [...item];
    },
    convertToFormData() {
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if (
          (item[1] && item[0] !== "image" && item[0] !== "photo") ||
          item[1] === 0
        ) {
          if (Array.isArray(item[1])) {
            item[1].forEach((it, index) => {
              if (typeof it === "object" && !Array.isArray(it) && it !== null) {
                Object.entries(it).forEach((el) => {
                  data.append(`${item[0]}[${index}][${el[0]}]`, el[1]);
                });
              } else {
                data.append(`${item[0]}[]`, it);
              }
            });
          } else {
            data.append(item[0], item[1]);
          }
        } else if (item[1] && (item[0] === "image" || item[0] === "photo")) {
          data.append(item[0], item[1][0]);
        }
      });
      // data.append('cbm', this.cbm);
      return data;
    },
    submitEditForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      let data = this.convertToFormData();
      ApiService.post("/fulfillment/skus/update", data)
        .then(() => {
          SwalEdited();
          this.toggleModal();
          this.refresher();
          this.pageLoader(false);
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetEditForm() {
      this.$v.$reset();
      this.formData = {
        customer_id: null,
        system_code: null,
        sku_type_id: null,
        rotation_rules: null,
        description: null,
        weight: null,
        length: null,
        width: null,
        height: null,
        brand_id: null,
        photo: null,
        expiry_control: 0,
        serial_number_control: 0,
        hs_code: null,
        country_id: null,
        currency_id: null,
        price: null,
        cost_price: null,
        category_id: null,
        size: null,
        color: null,
        design_number: null,
      };
    },
    toggleModal() {
      if (this.dialog) this.resetEditForm();
      else this.loadDataFromServer();
      this.dialog = !this.dialog;
    },
  },
  computed: {
    serverDataLoaded: function () {
      return !!this.serverData;
    },
    customer_idErrors: function () {
      return this.handleFormValidation("customer_id", this);
    },
    system_codeErrors: function () {
      return this.handleFormValidation("system_code", this);
    },
    sku_type_idErrors: function () {
      return this.handleFormValidation("sku_type_id", this);
    },
    // rotation_rulesErrors: function () {
    //   return this.handleFormValidation("rotation_rules", this);
    // },
    volume: function () {
      const { width, height, length } = this.formData;
      return width * height * length;
    },
    cbm() {
      const { width, height, length } = this.formData;
      const a = (width * height * length) / 1000000;
      return a + "";
    },
  },
};
</script>
